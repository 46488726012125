<template>
  <div class="container">
    <p class="title is-5">Страница не найдена</p>
  </div>
</template>

<script>


export default {
  name: 'NotFound',
}
</script>
