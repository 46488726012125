<template>
  <div>
    <p class='title'>Контакты</p>
    <div class="block">
      <p><strong>Email: </strong><a href="mailto:info@vaperate.ru">info@vaperate.ru</a></p>
      <p><strong>Telegram: </strong><a href="https://t.me/krsq01">@krsq01</a></p>
    </div>
  </div>
</template>

<script>
</script>